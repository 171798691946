import { selectHasWorkouts } from 'store/user/selectors';

import { useSelector } from 'hooks';

import { getSideBarPrimaryLinks } from './getSideBarPrimaryLinks';

const usePrimaryLinks = () => {
    const isHideWorkouts = useSelector(selectHasWorkouts);

    const primarySideBarLinks = getSideBarPrimaryLinks({ isHideWorkouts });

    return {
        primarySideBarLinks,
        filteredPrimarySideBarLinks: primarySideBarLinks.filter(({ isShowItem = true }) => isShowItem),
    };
};

export default usePrimaryLinks;
