import ReactModal from 'react-modal';
import React, { memo } from 'react';

import { Cross, RoundCross } from 'components/Icon';

import { ContentStyle, OverlayStyle, CloseButton, RoundCloseButton } from './styled';

import { IModal } from './types';

ReactModal.defaultStyles = {};

const Modal = ({
    children,
    isOpen,
    onClose,
    closeOnOverlayClick,
    withCloseButton,
    withCloseBtnForInsights,
    fullscreen,
    padding,
    dataLocator,
    style,
    ...reactModalProps
}: IModal) => (
    <ReactModal
        style={style}
        shouldCloseOnOverlayClick={Boolean(closeOnOverlayClick)}
        onRequestClose={onClose}
        shouldCloseOnEsc={false}
        isOpen={isOpen}
        contentLabel="Modal"
        contentElement={(props, content) => (
            <ContentStyle {...props} padding={padding} fullscreen={Boolean(fullscreen)}>
                {content}
            </ContentStyle>
        )}
        overlayElement={(props, contentElement) => (
            <OverlayStyle data-locator={dataLocator} {...props}>
                {contentElement}
            </OverlayStyle>
        )}
        ariaHideApp={false}
        {...reactModalProps}
    >
        {withCloseButton &&
            (withCloseBtnForInsights ? (
                <RoundCloseButton onClick={onClose}>
                    <RoundCross color="on-primary" />
                </RoundCloseButton>
            ) : (
                <CloseButton onClick={onClose}>
                    <Cross />
                </CloseButton>
            ))}
        {children}
    </ReactModal>
);

export default memo(Modal);
