import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { Alert } from 'components/Icon';

import * as S from './styled';

import { ISubscriptionRedemption } from '../types';

import SubscriptionData from './SubscriptionData';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';

const SubscriptionRedemption = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    subscriptionType,
}: ISubscriptionRedemption) => {
    const { t } = useTranslation();

    const subscriptionData = {
        subscriptionPeriod,
        subscriptionType,
        price,
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.AwaitingStatus data-locator="subscriptionStatus">
                <Alert color="text-main" />
                <Text type="small-text" medium text={t('subscription.text.awaitingPayment')} />
            </S.AwaitingStatus>
            <SubscriptionData data={subscriptionData} />
            <S.AwaitingBanner dataLocator="subscriptionInfoMessage">
                <Text medium text={t('subscription.text.awaitingPayment')} />
                <Text text={t('subscription.text.payYourDebt')} />
            </S.AwaitingBanner>
        </SubscriptionCardWrapper>
    );
};

export default SubscriptionRedemption;
