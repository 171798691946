import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';

import * as S from './styled';

import { IDiscountOfferProps } from './types';

const DiscountOffer = ({
    badgeText,
    badgePercent,
    badgeBackgroundColor,
    oldPrice,
    period,
    isNewOffer = false,
    currencySign,
    price,
    mb,
    onSubmitDiscount,
}: IDiscountOfferProps) => {
    const { t } = useTranslation();

    return (
        <S.Container mb={mb}>
            <S.Badge backgroundColor={badgeBackgroundColor}>
                <Text text={t(badgeText, { percent: badgePercent })} color="on-primary" medium center />
            </S.Badge>
            <S.Details paddingTop={20} paddingX={16} paddingBottom={16} backgroundColor="surface-secondary">
                <Text
                    type="small-button"
                    text={t('subscription.cancellation.specialOffer.namePeriodICU', { period })}
                    center
                    mb={20}
                />
                {oldPrice && (
                    <S.CrossedText type="large-text" medium color="text-secondary" center mb={8}>
                        <Trans
                            i18nKey="subscription.cancellation.specialOffer.oldPricePeriodCrossed"
                            values={{ currencySign, price: oldPrice, period }}
                        />
                    </S.CrossedText>
                )}
                <Text type="h2" medium center mb={isNewOffer ? 20 : 0}>
                    <Trans
                        i18nKey="subscription.cancellation.specialOffer.newPricePeriod"
                        values={{ currencySign, price, period }}
                        components={{
                            span: <S.Period />,
                        }}
                    />
                </Text>
                {isNewOffer && (
                    <>
                        <Text
                            type="caption"
                            text={t('subscription.cancellation.specialOffer.disclaimerNew')}
                            center
                            mb={20}
                        />
                        {onSubmitDiscount && (
                            <SecondaryDefaultButton
                                dataLocator="discountOfferChangePlanButton"
                                text={t('subscription.cancellation.specialOffer.button')}
                                onClick={onSubmitDiscount}
                            />
                        )}
                    </>
                )}
            </S.Details>
        </S.Container>
    );
};

export default DiscountOffer;
