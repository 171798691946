import { useTranslation } from 'react-i18next';
import React from 'react';

import { ModalName } from 'store/modals/types';

import { useModals } from 'hooks';

import * as S from './styled';

import { ReactComponent as CloseIcon } from './img/CloseIcon.svg';
import { ReactComponent as BackIcon } from './img/BackIcon.svg';

interface ICancelModalNavBarProps {
    isFirstModal?: boolean;
    closeModalAnalyticHandler?: () => void;
    isSpecialOffer?: boolean;
}

const CancelModalNavBar = ({ isFirstModal, isSpecialOffer, closeModalAnalyticHandler }: ICancelModalNavBarProps) => {
    const { t } = useTranslation();

    const { openModal, closeModal } = useModals();

    const onCloseModal = () => {
        closeModalAnalyticHandler && closeModalAnalyticHandler();

        closeModal();
    };

    return (
        <S.NavBarContainer>
            <S.Icon
                isHidden={isFirstModal}
                onClick={() => openModal(ModalName.reasonModal)}
                data-locator="cancelSubscriptionModalBackButton"
            >
                <BackIcon />
            </S.Icon>
            <S.NavBarTitle
                type="large-text"
                medium
                center
                text={t(isSpecialOffer ? 'subscription.cancellation.specialOffer' : 'subscription.text.cancel')}
            />
            <S.Icon onClick={onCloseModal} data-locator="cancelSubscriptionModalCloseButton">
                <CloseIcon />
            </S.Icon>
        </S.NavBarContainer>
    );
};

export default CancelModalNavBar;
