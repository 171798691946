import { ActionEventAction } from 'wikr-core-analytics';

import { PATHS } from 'constants/routerPaths';

import workouts from '../img/workouts.webp';
import timer from '../img/Timer.webp';
import settings from '../img/Settings.webp';
import plan from '../img/Plan.webp';
import guide from '../img/Guide.webp';
import dashboard from '../img/Dashboard.webp';
import app from '../img/App.webp';

export const getSideBarPrimaryLinks = ({ isHideWorkouts = false }) => {
    return [
        {
            to: PATHS.DASHBOARD,
            title: 'pagesTitles.dashboard',
            icon: dashboard,
            dataLocator: 'burgerDashboard',
        },
        {
            to: PATHS.MAIN,
            title: 'sidebar.app',
            icon: app,
            dataLocator: 'burgerFastEasyApp',
            analyticActionEvent: ActionEventAction.app,
        },
        // TODO:this is tem solution, we don't need to delete this code
        // {
        //     to: PRIVATE.INSIGHTS_MAIN.path,
        //     title: 'pagesTitles.insights',
        //     icon: insights,
        //     dataLocator: 'burgerInsights',
        // },
        {
            to: PATHS.FITNESS_GUIDES,
            title: 'sidebar.guides',
            icon: guide,
            dataLocator: 'burgerGuides',
            analyticActionEvent: ActionEventAction.guides,
        },
        {
            to: PATHS.WELCOME,
            title: 'sidebar.welcome', // What is fasting
            icon: timer,
            dataLocator: 'burgerWhatIsFasting',
        },
        {
            to: PATHS.WORKOUTS,
            title: 'pagesTitles.workouts',
            icon: workouts,
            dataLocator: 'burgerWorkouts',
            isShowItem: isHideWorkouts,
        },
        {
            to: PATHS.MY_FASTING_PLAN,
            title: 'sidebar.myFastingPlan',
            icon: plan,
            dataLocator: 'burgerMyFastingPlan',
        },
        {
            to: PATHS.ACCOUNT_DEFAULT,
            title: 'sidebar.settings',
            icon: settings,
            dataLocator: 'burgerAccountSettings',
            analyticActionEvent: ActionEventAction.accountSettings,
        },
    ];
};
