import { RootReducer } from '../rootReducer';

export const selectCurrentUser = ({ user }: RootReducer) => user;
export const selectGender = ({ user }: RootReducer) => user.gender;
export const selectFitnessLevel = ({ user }: RootReducer) => user.fitness_level;

export const selectCurrentUserId = ({ user }: RootReducer) => user.user_id;
export const selectUserFirstName = ({ user }: RootReducer) => user.name;
export const selectHasWorkouts = ({ user }: RootReducer) =>
    Boolean(user.product_list?.workouts || user.product_list?.fatburn);

export const selectHasLibraryContent = ({ user }: RootReducer) => {
    return {
        hasWorkouts: Boolean(user.product_list?.workouts || user.product_list?.fatburn),
        hasMealPlan: Boolean(user.product_list?.meal || user.product_list?.meal_plan),
        hasPurchasedGuides: Boolean(user.product_list?.fat_burning_gd || user.product_list?.weight_loss_gd),
    };
};

export const selectIsCalifornia = ({ user }: RootReducer) => user.is_california;
