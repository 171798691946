import { TFunction } from 'i18next';

import { PRODUCT_CODES } from 'constants/product';

import warningTriangle from 'assets/img/warningTriangle.webp';

export const getVipSupportCancelModalData = (t: TFunction) => {
    return {
        img: warningTriangle,
        title: t('subscription.cancelFlow.vipSupport.cancelModal.title'),
        subtitle: t('subscription.cancelFlow.vipSupport.cancelModal.subtitle'),
        mainBtnText: t('subscription.text.cancel'),
        mainBtnDataLocator: 'updatingSubscriptionPlanCancelSubscription',
        outlineBtnText: t('subscription.cancelDialog.discard'),
        outlineBtnDataLocator: 'updatingSubscriptionPlanKeepSubscription',
    };
};

export const getSecondSubscriptionCancelModalData = ({
    t,
    productCode,
    expirationDateUI,
}: {
    t: TFunction;
    productCode: string;
    expirationDateUI: string;
}) => {
    const preparedProductCode =
        productCode === PRODUCT_CODES.APP_MEAL_FATBURN_GUIDES ? PRODUCT_CODES.APP_MEAL_FATBURN : productCode;

    return {
        img: warningTriangle,
        title: t('subscription.cancelFlow.secondSubscription.cancelModal.title', { productCode: preparedProductCode }),
        subtitle: t('subscription.cancelFlow.secondSubscription.cancelModal.subtitle', {
            productCode: preparedProductCode,
            expirationDateUI,
        }),
        mainBtnText: t('subscription.text.cancel'),
        mainBtnDataLocator: 'updatingSubscriptionPlanCancelSubscription',
        outlineBtnText: t('subscription.cancelDialog.discard'),
        outlineBtnDataLocator: 'updatingSubscriptionPlanKeepSubscription',
    };
};
