import { Box, Input, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticDeleteUserClick } from 'services/analytics';

import { useSelector } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import { DeletionModal } from 'components/Modal';

import * as S from './styled';

import { InputType } from 'types/ui';

import { getContentData } from './config';

const AccountSettings = ({ theme }: { theme: Theme }) => {
    useScreenLoad();

    const [isDeletionTabOpened, setIsDeletionTabOpened] = useState(false);

    const navigate = useNavigate();

    const user: any = useSelector(selectCurrentUser);

    const { t } = useTranslation();

    const { inputData, deleteData } = getContentData(user.email);

    const deleteModalOpenHandler = () => {
        sendAnalyticDeleteUserClick();

        setIsDeletionTabOpened(true);
    };

    const deleteModalCloseHandler = () => {
        setIsDeletionTabOpened(false);
    };

    return (
        <Box paddingTop={40}>
            {inputData.map(({ field, icon, value, label, buttonRoute, buttonLocator }) => (
                <Fragment key={label}>
                    <S.StyledInput>
                        <Input
                            name={field}
                            label={label}
                            touched
                            leftIcon={icon}
                            disabled
                            isFocus
                            value={value}
                            initialValue={value}
                            onChange={() => {}}
                            theme={theme}
                            type={field as InputType}
                        />
                    </S.StyledInput>

                    {/* todo: remove 'styled' after lib issue will be fixed*/}
                    <S.SmallButton
                        size="small"
                        dataLocator={buttonLocator || 'changeButton'}
                        mb={40}
                        onClick={() => navigate(buttonRoute)}
                        text={t('account.settings.button.change')}
                    />
                </Fragment>
            ))}
            <OutlinedButton onClick={deleteModalOpenHandler} text={t('account.settings.button.delete')} />
            <DeletionModal
                captions={deleteData}
                isOpen={isDeletionTabOpened}
                onClose={deleteModalCloseHandler}
                onDiscard={deleteModalCloseHandler}
            />
        </Box>
    );
};

export default withTheme(AccountSettings);
