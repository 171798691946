import { Trans } from 'react-i18next';
import { FC } from 'react';

import * as S from './styled';

import { RadioProps } from './types';

export const Radio: FC<RadioProps> = ({ checked, label, onChange, name, value }) => {
    const id = `radio_${name}_${value}`;

    return (
        <S.RadioWrapper>
            <S.Input type="radio" name={name} onChange={onChange} id={id} value={value} checked={checked} />
            <S.RadioLabel htmlFor={id}>
                <S.Radio />
                <S.RadioText type="medium-text">
                    <Trans i18nKey={label} />
                </S.RadioText>
            </S.RadioLabel>
        </S.RadioWrapper>
    );
};
