import { Box, Image, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const LoginBlock = styled(Box)`
    position: relative;
    border-radius: 24px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaQueries.laptop} {
        padding: 48px 0;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 0;
        min-height: 596px;
        align-items: start;
        justify-content: center;
    }
`;

export const TitleWrap = styled(Box)`
    padding: 0 20px;
    margin-bottom: 40px;

    ${mediaQueries.laptop} {
        padding: 0 48px;
    }

    ${mediaQueries.theSmallestDesktop} {
        width: 100%;
        max-width: 517px;
        padding: 0 0 0 48px;
    }
`;

export const LoginTitle = styled(Text)`
    font-size: 28px;
    line-height: 36px;

    ${mediaQueries.laptop} {
        font-size: 36px;
        line-height: 44px;
    }
`;

export const VideoBackgroundArrowWrap = styled.div`
    max-width: 400px;

    ${mediaQueries.theSmallestDesktop} {
        margin-bottom: 0;
        position: absolute;
        top: 99px;
        right: 134px;
    }
`;

export const VideoImgWrap = styled.div`
    position: relative;
    margin-bottom: 22px;
`;

export const ArrowWrap = styled.div`
    position: absolute;
    z-index: 2;
    top: 80px;
    right: 222px;
`;

export const Video = styled(Image)`
    position: relative;
    z-index: 1;
    border: 6px solid ${color('border-default')};
    border-radius: 20px;
`;

export const Test = styled.div`
    position: absolute;
    width: 343px;
    left: -80px;
    top: -24px;
    z-index: 0;

    ${mediaQueries.tablet} {
        width: 400px;
        left: -110px;
        top: -52px;
    }
`;

export const ContactUsBlock = styled.div`
    width: 100%;

    ${mediaQueries.theSmallestDesktop} {
        max-width: 517px;
        padding: 0 0 0 48px;
    }
`;
