import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { selectCurrentUser } from 'store/user/selectors';
import { UPDATE_USER } from 'store/user/actionTypes';
import { updateUserRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { measure } from 'constants/units';
import { initialValues, metricMeasuresKeys, imperialMeasuresKeys, imperialMeasureFields } from './constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticProfileUpdateError, sendAnalyticProfileUpdateSuccess } from 'services/analytics';

import { useSelector, useDispatch } from 'hooks/store';

import getKeyByValue from 'helpers/getKeyByValue';
import { getAgeFromBirthday } from 'helpers/date';
import { cmToFeetAndInch, fromFeetAndInchesToCentimeters, fromKgToLbs, fromLbsToKg } from 'helpers/convert';
import { getWeightFields, getNameFields, getUserUpdatedKeys } from './helpers';

import ProfileForm from './components/ProfileForm';

import { UnitTypes, UserUpdateData } from 'types/user/userApiInterface';
import { UserStore } from 'types/store/userStore';
import { formValues } from './types';

import getValidationSchema from './validationScheme';

const ProfileContainer = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser) as UserStore;
    const isLoading: boolean = useSelector(createProcessingSelector([UPDATE_USER]));

    const [currentUnit, setCurrentUnit] = useState(currentUser.units || UnitTypes.Imperial);
    const { MEASURE_UNITS } = measure;

    const onSubmit = (values: formValues) => {
        const {
            weight: currentWeight,
            heightMetric,
            heightImperialFt,
            heightImperialIn,
            age,
            gender,
            name,
            last_name,
        } = values;

        const weight = currentUnit === UnitTypes.Metric ? currentWeight : fromLbsToKg(currentWeight);
        const height =
            currentUnit === UnitTypes.Metric
                ? heightMetric
                : fromFeetAndInchesToCentimeters(heightImperialFt, heightImperialIn);

        const payload = {
            age: Number(age),
            units: currentUnit,
            weight: Number(weight),
            height: Number(height),
            gender,
            name: name.trim(),
            last_name: last_name.trim(),
        };

        const userInitialValues = getFormDataFromUser(currentUser);
        const updatedUserValueKeys = getUserUpdatedKeys(userInitialValues, values);

        dispatch(
            updateUserRequest({
                payload,
                onSuccess: () => {
                    sendAnalyticProfileUpdateSuccess(updatedUserValueKeys);
                    formik.setErrors({});
                    formik.setTouched({});
                },
                onError: sendAnalyticProfileUpdateError,
            })
        );
    };

    const getFormDataFromUser = (user: UserUpdateData) => {
        const {
            name = initialValues.name,
            last_name = initialValues.last_name,
            gender = initialValues.gender,
            weight,
            height,
            units,
            date_of_birth: dateOfBirth,
        } = user;
        const formUserData = {
            ...initialValues,
            name,
            last_name,
            gender,
            age: getAgeFromBirthday(`${dateOfBirth}`),
        };

        if (units === UnitTypes.Metric) {
            formUserData.weight = Number(weight);
            formUserData.heightMetric = Number(height);
        }

        if (units === UnitTypes.Imperial) {
            const { feet, inch } = cmToFeetAndInch(Number(height));

            formUserData.weight = fromKgToLbs(Number(weight));
            formUserData.heightImperialFt = feet;
            formUserData.heightImperialIn = inch;
        }

        return formUserData;
    };

    const formik = useFormik({
        initialValues: getFormDataFromUser(currentUser),
        validationSchema: getValidationSchema(t, currentUnit),
        onSubmit,
    });

    const resetMeasures = (newUnit: UnitTypes) => {
        const measures = {
            weight: 0,
            heightImperialFt: 0,
            heightImperialIn: 0,
            heightMetric: 0,
        };

        if (currentUser.units === newUnit) {
            const { weight, heightImperialFt, heightImperialIn, heightMetric } = getFormDataFromUser(currentUser);

            measures.weight = weight;
            measures.heightImperialFt = heightImperialFt;
            measures.heightImperialIn = heightImperialIn;
            measures.heightMetric = heightMetric;
        }

        formik.setValues({ ...formik.values, ...measures });
    };

    useEffect(() => {
        // There is we check the values of the new unit system and validate them to avoid submitting with empty values
        if (currentUser.units === currentUnit) {
            const commonKeys = Array.from(new Set([...metricMeasuresKeys, ...imperialMeasuresKeys]));
            const errorsCopy = { ...formik.errors };
            const touchedCopy = { ...formik.touched };

            commonKeys.forEach((key) => {
                delete errorsCopy[key as keyof typeof errorsCopy];
                delete touchedCopy[key as keyof typeof touchedCopy];
            });

            formik.setTouched(touchedCopy);
            formik.setErrors(errorsCopy);

            return;
        }

        const validateField = (fieldName: string) => {
            formik.validateField(fieldName);
            formik.setFieldTouched(fieldName);
        };

        if (currentUnit === UnitTypes.Metric) {
            metricMeasuresKeys.forEach(validateField);
        }

        if (currentUnit === UnitTypes.Imperial) {
            const imperialKeysWithoutInch = imperialMeasuresKeys.filter(
                (key) => key !== imperialMeasureFields.heightImperialIn
            );

            imperialKeysWithoutInch.forEach(validateField);
        }
    }, [currentUnit]);

    const setUnit = (event: ChangeEvent) => {
        // todo: remove it after lib issue with returned type will be fixed
        // https://gitlab.asqq.io/vendor-react/components/-/issues/27
        const el = event.target as HTMLInputElement;

        const newUnit: UnitTypes = getKeyByValue(MEASURE_UNITS, el.value || '') as UnitTypes;

        resetMeasures(newUnit);
        setCurrentUnit(newUnit);
    };

    return (
        <ProfileForm
            nameFields={getNameFields(t)}
            weightFields={getWeightFields(t)}
            currentUnit={currentUnit}
            setUnit={setUnit}
            isLoading={isLoading}
            {...formik}
        />
    );
};

export default ProfileContainer;
