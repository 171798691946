import { measure } from 'constants/units';

import { IUserFormData, nameInput, weightInput } from './types';

const { TALL_UNIT } = measure;

const NUMBERED_FIELDS = ['age', 'heightImperialFt', 'heightImperialIn', 'heightMetric', 'weight'];

export const getWeightFields = (t: (key: string) => string): weightInput[] => {
    return [
        {
            field: 'heightImperialFt',
            unit: TALL_UNIT.imperial.ft,
            min: 4,
            max: 7,
            label: t('account.profile.height'),
            placeholder: t('account.profile.height'),
        },
        {
            field: 'heightImperialIn',
            unit: TALL_UNIT.imperial.in,
            min: 0,
            max: 11,
            label: t('account.profile.height'),
            placeholder: t('account.profile.height'),
        },
    ];
};

export const getNameFields = (t: (key: string) => string): nameInput[] => [
    {
        name: 'name',
        label: t('account.profile.firstName'),
        dataLocator: 'firstName',
        placeholder: t('account.profile.firstName'),
    },
    {
        name: 'last_name',
        label: t('account.profile.lastName'),
        dataLocator: 'lastName',
        placeholder: t('account.profile.lastName'),
    },
];

export const getUserUpdatedKeys = (userInitValues: IUserFormData, formValue: { [field: string]: string | number }) => {
    const changedKeys: string[] = [];

    Object.keys(userInitValues).forEach((key) => {
        if (NUMBERED_FIELDS.includes(key)) {
            if (Number(userInitValues[key as keyof IUserFormData]) !== Number(formValue[key])) {
                changedKeys.push(key);
            }
        } else {
            if (userInitValues[key as keyof IUserFormData] !== formValue[key]) {
                changedKeys.push(key);
            }
        }
    });

    return changedKeys;
};
