import { PRODUCT_CODES } from './product';

export const SUBSCRIPTION_TITLE = {
    APP_FULL_ACCESS: 'subscription.heading.personalizedProgram',
    APP_VIP_SUPPORT: 'subscription.heading.vipSupport',
    APP_MEAL_FATBURN: 'subscription.heading.mealFatburn',
    APP_MEAL: 'subscription.heading.meal',
    APP_FATBURN: 'subscription.heading.fatburn',
    APP_RECIPE_LIBRARY: 'subscription.heading.recipeLibrary',
    APP_ALL_COURSES: 'subscription.heading.allCourses',
    OTHER: 'subscription.type.subscription',
};

export const SUBSCRIPTION_HEADINGS = {
    [PRODUCT_CODES.APP_FULL_ACCESS]: SUBSCRIPTION_TITLE.APP_FULL_ACCESS,
    [PRODUCT_CODES.APP_VIP_SUPPORT]: SUBSCRIPTION_TITLE.APP_VIP_SUPPORT,
    [PRODUCT_CODES.APP_MEAL_FATBURN]: SUBSCRIPTION_TITLE.APP_MEAL_FATBURN,
    [PRODUCT_CODES.APP_MEAL]: SUBSCRIPTION_TITLE.APP_MEAL,
    [PRODUCT_CODES.APP_FATBURN]: SUBSCRIPTION_TITLE.APP_FATBURN,
    [PRODUCT_CODES.APP_RECIPE_LIBRARY]: SUBSCRIPTION_TITLE.APP_RECIPE_LIBRARY,
    [PRODUCT_CODES.APP_ALL_COURSES]: SUBSCRIPTION_TITLE.APP_ALL_COURSES,
    [PRODUCT_CODES.APP_MEAL_FATBURN_GUIDES]: SUBSCRIPTION_TITLE.APP_MEAL_FATBURN,
};

export const AVAILABLE_CURRENCY: Record<string, string> = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    BRL: 'R$',
    MXN: 'M$',
    ARS: 'ARS',
    CLP: 'CLP',
    COP: 'COL$',
    PEN: 'S/',
};

export const DEFAULT_UNSUBSCRIBE_PLATFORM = 'WEB';

export const DEFAULT_CANCEL_SUBSCRIPTION_ERROR = 'Subscription is not cancelled';
export const DEFAULT_UPDATE_SUBSCRIPTION_ERROR = 'Subscription is not updated';
export const DEFAULT_SUBSCRIPTION_REQUEST_ERROR = 'Something went wrong';

export enum DISCOUNT_TYPES {
    base = 'base',
    extra = 'extra',
}
