import { Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const NavBarContainer = styled.div`
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${color('border-default')};
    padding: 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 16px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 16px 40px;
    }
`;

export const NavBarTitle = styled(Text)`
    width: 100%;
`;

export const Icon = styled.button<{ isHidden?: boolean }>`
    min-width: 44px;
    height: 44px;
    padding: 12px;
    border-radius: 100px;
    background-color: ${color('surface-secondary')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${({ isHidden }) =>
        isHidden &&
        css`
            visibility: hidden;
        `}

    &:hover {
        background-color: ${color('surface-hovered')};

        svg path {
            fill: ${color('action-hovered')};
        }
    }
`;
