import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectHasLibraryContent } from 'store/user/selectors';

import { PATHS } from 'constants/routerPaths';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticLibraryButtonClick } from 'services/analytics/trackers/webview';

import { useGuides, useSelector } from 'hooks';

import GuidesSkeleton from 'pages/guides/GuidesSkeleton';

import PlanCard from './components/PlanCard/PlanCard';
import GuidesList from './components/GuidesList/GuidesList';

import * as S from './styled';

import meal from './img/meal.webp';
import fire from './img/fire.webp';

const WebViewLibrary = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const { purchasedGuides, isLoading } = useGuides(['fat_burning_gd', 'weight_loss_gd']);
    const { hasWorkouts, hasMealPlan, hasPurchasedGuides } = useSelector(selectHasLibraryContent);

    const navigate = useNavigate();

    const handleCardClick = (path: typeof PATHS[keyof typeof PATHS], blockName: string) => {
        navigate(path);
        sendAnalyticLibraryButtonClick(blockName);
    };

    return (
        <S.WebViewContainer>
            <Text type="h3" medium text={t('basics.library')} mb={24} />

            <S.CardsWrap mb={40}>
                {hasWorkouts && (
                    <PlanCard
                        text={t('library.button.workouts')}
                        img={fire}
                        onClick={() => handleCardClick(PATHS.WORKOUTS, 'workout')}
                    />
                )}
                {hasMealPlan && (
                    <PlanCard
                        text={t('library.button.mealPlan')}
                        img={meal}
                        onClick={() => handleCardClick(PATHS.MEAL_PLAN, 'meal_plan')}
                    />
                )}
            </S.CardsWrap>

            {hasPurchasedGuides && (
                <>
                    <Text type="h6" text={t('library.title.guides')} mb={16} />
                    <S.GuidesListWrapper>
                        {isLoading && <GuidesSkeleton />}

                        <GuidesList guides={purchasedGuides} dataLocator="purchasedGuide" />
                    </S.GuidesListWrapper>
                </>
            )}
        </S.WebViewContainer>
    );
};

export default WebViewLibrary;
