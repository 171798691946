import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled.div<{ backArrow?: boolean }>`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    position: relative;
    left: 0;
    transition: left 0.5s;

    &:hover {
        left: ${({ backArrow }) => (backArrow ? '-5px' : '5px')};
    }
`;

export const Label = styled.label`
    font-size: 16px;
    line-height: 24px;
    color: ${color('action-main')};
    padding-right: 12px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: color 0.5s;

    &:hover {
        color: ${color('action-hovered')};
    }

    &:active {
        color: ${color('action-pressed')};
    }

    @media (min-width: 768px) {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const Button = styled.button<{ backArrow?: boolean; isAbsoluteOnStack?: boolean }>`
    height: 24px;
    width: 24px;
    padding: 7px;
    cursor: pointer;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    background: ${color('surface-main')};
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.5s;

    & > svg path {
        transition: fill 0.5s;
    }

    & ~ label {
        transition: color 0.5s;
    }

    ${({ backArrow }) =>
        backArrow &&
        css`
            > svg {
                transform: rotate(-180deg);
            }
        `}

    &:hover {
        background: ${color('action-hovered')};

        > svg path {
            fill: ${color('on-primary')};
        }

        & ~ label {
            color: ${color('action-hovered')};
        }
    }

    &:active {
        background: ${color('action-pressed')};

        > svg path {
            fill: ${color('on-primary')};
        }

        & ~ label {
            color: ${color('action-pressed')};
        }
    }

    @media (min-width: 768px) {
        height: 32px;
        width: 32px;
        padding: 0;
    }

  ${({ isAbsoluteOnStack }) =>
      isAbsoluteOnStack &&
      css`
          z-index: 4;
          position: absolute;
          top: 50px;
          left: 203px;
          width: 16px;
          height: 16px;
          padding: 4px;

          ${mediaQueriesGrid.tablet} {
              top: 12px;
              left: 12px;
              width: 20px;
              height: 20px;
              padding: 6px;
          }
      `};
}
`;
