import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { RefObject, useEffect, useRef } from 'react';

import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticDeeplinkLoadClick, sendAnalyticDownloadAppClick } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks';

import isMobile from 'helpers/isMobile';
import { getWindowSize } from 'helpers/getWindowSize';
import { getVideoLink } from 'helpers/getVideoLink';

import LoginBlock from './components/LoginBlock';
import FeaturesBlock from './components/FeaturesBlock';
import DownloadBlock from './components/DownloadBlock';

import * as S from './styled';

export const InstallApp = () => {
    useScreenLoad();

    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const dispatch = useDispatch();
    const deepLinkUrl = useSelector(selectDeepLink);

    const isBigScreen = getWindowSize(768);

    const { t } = useTranslation();

    const videoLink = getVideoLink();
    const isAndroid = isMobile.android();
    const isIos = isMobile.iOS();

    const refForScroll = useRef<HTMLDivElement | null>(null);

    const handlePlanSmoothClick = (ref: RefObject<HTMLDivElement>) => {
        ref?.current?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
    };

    const handleDownloadApp = () => {
        sendAnalyticDownloadAppClick();

        // Workaround for window.open method since it doesn't work for Safari browser
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: deepLinkUrl,
        }).click();
    };

    useEffect(() => {
        if (!deepLinkUrl) {
            dispatch(getDeepLinkRequest({ onSuccess: sendAnalyticDeeplinkLoadClick }));
        }
    }, []);

    return (
        <>
            <S.Container backgroundColor="surface-secondary">
                <S.ContentContainer>
                    <Text type="h5" mb={32} bold center text={t('main.title.isDownloaded')} />
                    <DownloadBlock
                        isLoading={isLoading}
                        deepLinkUrl={deepLinkUrl}
                        isBigScreen={isBigScreen}
                        isAndroid={isAndroid}
                        blockRef={refForScroll}
                        handleDownloadApp={handleDownloadApp}
                    />
                    <FeaturesBlock
                        isAndroid={isAndroid}
                        isIos={isIos}
                        onFeatureClick={() => handlePlanSmoothClick(refForScroll)}
                    />
                    <LoginBlock isBigScreen={isBigScreen} videoLink={videoLink} />
                </S.ContentContainer>
            </S.Container>
        </>
    );
};
