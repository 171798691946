import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import { TOKEN_KEY } from 'api/constants';

import sentry from 'services/Sentry/SentryInstance';

import { initApiClient } from 'welltech-web-api-client';

function ApiClient() {
    return initApiClient({
        apiUrl: config.API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.API_KEY,
        authTokenKey: TOKEN_KEY,
        retries: 3,
        timeout: 35000,
        extendResponse: false,
        onUpdateToken: (token) => {
            localStorage.setItem(TOKEN_KEY, token);
        },
        onRetry: (retryCount, error, requestConfig) => {
            sentry.logError(error as Error, SENTRY_AXIOS, ERROR_LEVELS.WARNING, { ...requestConfig });
        },
        onErrorHandler: ({ extras, status, message }) => {
            const level = Number(status) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            sentry.logError(new Error(message), SENTRY_AXIOS, level, extras);
        },
    });
}

export default ApiClient();
