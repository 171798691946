import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import videoBackground from './img/videoBackground.webp';
import { ReactComponent as Arrow } from './img/Arrow.svg';

function LoginBlock({ videoLink, isBigScreen }: { videoLink: string; isBigScreen: boolean }) {
    const { t } = useTranslation();

    return (
        <S.LoginBlock backgroundColor="surface-main">
            <S.TitleWrap>
                <S.LoginTitle center text={t('main.loginBlock.title')} />
            </S.TitleWrap>
            <S.VideoBackgroundArrowWrap>
                <S.VideoImgWrap>
                    <S.Video maxWidth={190} mb={30} alt="video" src={videoLink} />
                    <S.Test>
                        <Image isForceVisible src={videoBackground} alt="videoBackground" />
                    </S.Test>
                    {isBigScreen && (
                        <S.ArrowWrap>
                            <Arrow />
                        </S.ArrowWrap>
                    )}
                </S.VideoImgWrap>
            </S.VideoBackgroundArrowWrap>
            <S.ContactUsBlock>
                <Text type="medium-text" center text={t('main.loginBlock.cant')} mb={4} />
                <Text type="medium-text" center text={t('main.loginBlock.help')} bold mb={24} />
                <ContactUsLink data-locator="subscriptionFaqBtn" subject="login" />
            </S.ContactUsBlock>
        </S.LoginBlock>
    );
}

export default LoginBlock;
