import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { usePauseSubscription } from 'hooks';

import { Pause } from 'components/Icon';

import * as S from './styled';

import { IPausedSubscription } from '../types';

import SubscriptionData from './SubscriptionData';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import CancelButton from './CancelButton';

const PausedSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    subscriptionType,
    isMainSubscription,
}: IPausedSubscription) => {
    const { t } = useTranslation();

    const { onResumeSubscription } = usePauseSubscription();

    const subscriptionData = {
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDate,
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.TemporaryStatus data-locator="subscriptionStatus">
                <Pause />
                <Text type="small-text" medium text={t('subscription.status.paused')} />
            </S.TemporaryStatus>
            <SubscriptionData data={subscriptionData} />
            <S.PausedBanner mb={20} dataLocator="subscriptionInfoMessage">
                <Text
                    medium
                    text={t('subscription.cancellation.pausedSubscription.bannerTitle', {
                        renewalDate: expirationDate,
                    })}
                />
                <Text text={t('subscription.cancellation.pausedSubscription.bannerText')} />
            </S.PausedBanner>
            {isMainSubscription && (
                <S.ResumeButton
                    backgroundColor="secondary-default"
                    hoverColor="secondary-hovered"
                    text={t('subscription.text.resumeSubscription')}
                    mb={20}
                    onClick={onResumeSubscription}
                    dataLocator="resumeSubscriptionButton"
                />
            )}
            <CancelButton onCancel={onCancelSubscription} />
        </SubscriptionCardWrapper>
    );
};

export default PausedSubscription;
