import { TFunction } from 'i18next';

import sandClock from 'assets/img/subscriptionIcons/sandClock.webp';

export const getUpdatingSubscriptionLoadingData = (t: TFunction) => {
    return {
        img: sandClock,
        title: t('subscription.cancellation.specialOffer.updatingSubscription'),
    };
};

export const getSeparatePrice = (amount: number) => {
    const [integer, cents = '00'] = `${Number(amount) / 100}`.split('.');

    return { integer, cents };
};
