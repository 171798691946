import { Provider } from 'wikr-core-analytics';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import isPrivateRoute from 'router/helpers/isPrivateRoute';

import { getToken, removeToken, setToken } from 'api/utils/tokenManagement';

import { setIsAppWebView, setIsWorkoutsAppWebView } from 'store/uiEffects/actions';
import { setResetCRMCandidateSubscriptionId } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';
import {
    authentication,
    authenticationBySignature,
    setAuthenticationStatus,
    setAuthRedirectUrl,
} from 'store/auth/actions';

import { accountTabs } from 'containers/AccountSettings/constants';
import { PATHS } from 'constants/routerPaths';
import { AUTH_TOKEN_NAME } from 'constants/api';
import { BUNDLE_IDS, URL_PARAMS } from './constants';

import { initAnalytics } from 'services/initAnalytics';
import { initHotjar } from 'services/Hotjar';
import { sendAnalyticLibraryStartSession, sendAnalyticWorkoutStartSession } from 'services/analytics/trackers/workouts';
import { sendAnalyticCabinetStartSession } from 'services/analytics';

import { useDispatch } from 'hooks/store';
import { useModals } from 'hooks/modals';

import { getRedirectPage } from './helpers';

const useAuthentication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openModal } = useModals();

    const { search, pathname } = useLocation();

    const isPrivate = isPrivateRoute(pathname);

    const params = new URLSearchParams(search);

    useEffect(() => {
        const uId = params.get(URL_PARAMS.USER_ID);
        const signature = params.get(URL_PARAMS.SIGNATURE);
        const onboardingUserId = params.get(URL_PARAMS.USER_ID_ONBOARDING);
        const bundlesUpsellId = params.get(URL_PARAMS.BUNDLES_UPSELL_ID);
        const utmSource = params.get(URL_PARAMS.UTM_SOURCE) ?? 'default';

        initAnalytics();
        initHotjar();

        if (onboardingUserId) {
            Provider.setUserId(Number(onboardingUserId));
        }

        if (uId && signature) {
            const isWorkouts = bundlesUpsellId === BUNDLE_IDS.WORKOUTS || bundlesUpsellId === BUNDLE_IDS.FATBURN;
            const isLibrary = bundlesUpsellId === BUNDLE_IDS.LIBRARY;

            const redirectPage = getRedirectPage(bundlesUpsellId);

            dispatch(setAuthRedirectUrl(redirectPage));
            dispatch(
                authenticationBySignature({
                    uId,
                    signature,
                    onSuccess: () => {
                        if (isWorkouts) {
                            dispatch(setIsWorkoutsAppWebView(true));

                            sendAnalyticWorkoutStartSession(utmSource);
                        }
                        if (isLibrary) {
                            dispatch(setIsAppWebView(true));

                            sendAnalyticLibraryStartSession(utmSource);
                        }
                        navigate(redirectPage, { replace: isWorkouts || isLibrary });

                        sendAnalyticCabinetStartSession();
                    },
                    onError: () => {
                        navigate(PATHS.LOGIN);
                    },
                })
            );

            return;
        }

        const tokenFromUrl = params.get(AUTH_TOKEN_NAME);

        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }

        if (!getToken()) {
            isPrivate && dispatch(setAuthRedirectUrl(pathname));

            dispatch(setAuthenticationStatus(false));

            return;
        }

        const subscription_id = params.get(URL_PARAMS.SUBSCRIPTION_ID);
        const isReturnOfferCRM = params.get(URL_PARAMS.RETURN_OFFER) === 'true';

        if (subscription_id && isReturnOfferCRM) {
            const subscriptionsPage = `${PATHS.ACCOUNT_DEFAULT}/${accountTabs.Subscription}`;

            dispatch(setResetCRMCandidateSubscriptionId(subscription_id));
            dispatch(setAuthRedirectUrl(subscriptionsPage));
            dispatch(
                authentication({
                    onSuccess: () => {
                        navigate(subscriptionsPage);
                        sendAnalyticCabinetStartSession();

                        openModal(ModalName.returnOfferCRMModal);
                    },
                    onError: () => {
                        removeToken();
                        isPrivate && dispatch(setAuthRedirectUrl(pathname));
                        navigate(PATHS.LOGIN);
                    },
                })
            );

            return;
        }

        dispatch(
            authentication({
                onSuccess: () => {
                    navigate(isPrivate ? pathname : PATHS.MAIN);
                    sendAnalyticCabinetStartSession();
                },
                onError: () => {
                    removeToken();
                    isPrivate && dispatch(setAuthRedirectUrl(pathname));
                    navigate(PATHS.LOGIN);
                },
            })
        );
    }, []);
};

export default useAuthentication;
