import { put, takeLatest, all, call } from 'redux-saga/effects';

import api from 'api';

import { updateUserSuccess } from 'store/user/actions';

import { fetchGuides, setGuides, setLoadingState, setOfferGuides as setOfferGuidesAction } from './actions';
import { notifyError } from '../notifications/actions';

import * as actionTypes from './actionTypes';

import { UserStore } from 'types/store/userStore';
import { IGuide } from 'types/guide';

function* getGuides({ payload }: ReturnType<typeof fetchGuides>) {
    try {
        const guidesQueryParams = payload?.map((code) => `guide_code=${code}`).join('&');
        const response: IGuide[] = yield call(api.user.getProducts, { productCodes: guidesQueryParams });

        yield put(setGuides(response));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getGuides error');
    }
}

function* setOfferGuides({ payload }: ReturnType<typeof setOfferGuidesAction>) {
    const { onSuccess, onError } = payload;

    try {
        const userData: UserStore = yield call(api.user.update, { guides_offer: true });

        yield put(updateUserSuccess(userData));
        yield call(onSuccess);
    } catch (error) {
        yield call(onError, error as string);
    }
}

export const guidesTestData = {
    getGuides,
    setOfferGuides,
};

export default function* watchGuides() {
    yield all([takeLatest(actionTypes.FETCH_GUIDES, getGuides)]);
    yield all([takeLatest(actionTypes.SET_OFFER_GUIDES, setOfferGuides)]);
}
