import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';
import PauseOffer from './subcomponents/PauseOffer';

import * as S from './styled';

interface PauseOfferModalProps {
    onCancelSubscription: () => void;
    onPauseSubscription: () => void;
}

const PauseOfferModal = ({ onCancelSubscription, onPauseSubscription }: PauseOfferModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            fullscreen
            style={{
                content: MODAL_CONTENT_FULL_SCREEN_STYLES,
            }}
        >
            <>
                <CancelModalNavBar />
                <S.Container dataLocator="pauseOfferContainer">
                    <Text type="h4" medium text={t('subscription.cancellation.pauseOffer.title')} mb={12} center />
                    <Text type="large-text" text={t('subscription.cancellation.pauseOffer.subtitle')} mb={24} center />
                    <PauseOffer
                        mb={24}
                        badgeBackgroundColor="discount"
                        badgeText="subscription.cancellation.pauseOffer.badgeText"
                        onPauseSubscription={onPauseSubscription}
                    />
                    <OutlinedButton
                        dataLocator="pauseOfferCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={onCancelSubscription}
                    />
                </S.Container>
            </>
        </Modal>
    );
};

export default memo(PauseOfferModal);
