import { Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const AlarmIcon = ({ theme }: { theme: Theme }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99902 14.6665C4.70347 14.6231 2.04246 11.9621 1.99902 8.6665C2.04246 5.37096 4.70347 2.70994 7.99902 2.6665C11.2946 2.70994 13.9556 5.37096 13.999 8.6665C13.9556 11.9621 11.2946 14.6231 7.99902 14.6665ZM7.99902 3.99984C5.4358 4.03358 3.3661 6.10328 3.33236 8.6665C3.3661 11.2297 5.4358 13.2994 7.99902 13.3332C10.5622 13.2994 12.6319 11.2297 12.6657 8.6665C12.6319 6.10328 10.5622 4.03358 7.99902 3.99984Z"
                fill={theme['text-main']}
            />
            <path
                d="M13.3 8.6665C13.3 11.5936 10.9271 13.9665 8 13.9665C5.07289 13.9665 2.7 11.5936 2.7 8.6665C2.7 5.73939 5.07289 3.3665 8 3.3665C10.9271 3.3665 13.3 5.73939 13.3 8.6665Z"
                stroke={theme['text-main']}
                strokeWidth="1.4"
            />
            <path d="M2.66699 3.33333L4.00033 2" stroke={theme['text-main']} strokeWidth="1.4" strokeLinecap="round" />
            <path d="M12 2.00016L13.3333 3.3335" stroke={theme['text-main']} strokeWidth="1.4" strokeLinecap="round" />
            <path
                d="M8 6.3335L8.0001 9.66731L9.33333 10.6668"
                stroke={theme['text-main']}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default withTheme(AlarmIcon);
