import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    width: 100%;
    border-radius: 20px;
`;

export const Badge = styled(Box)`
    width: 100%;
    border-radius: 20px 20px 0 0;
`;

export const Details = styled(Box)`
    width: 100%;
    border-radius: 0 0 20px 20px;
`;

export const CrossedText = styled(Text)`
    text-decoration: line-through;
    text-decoration-color: #482525;
`;

export const Period = styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
`;
