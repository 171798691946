import Workouts from './components/Workouts';
import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';

function ApiInstance() {
    const user = new UserApi();
    const subscriptions = new Subscriptions();
    const workouts = new Workouts();

    return {
        user,
        subscriptions,
        workouts,
    };
}

export default ApiInstance();
