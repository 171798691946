import { Button, Text, Box } from 'wikr-core-components';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { CHANGE_PASSWORD } from 'store/user/actionTypes';
import { changePasswordRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { accountTabs } from 'containers/AccountSettings/constants';
import { PATHS } from 'constants/routerPaths';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticPasswordUpdate, sendAnalyticSaveNewPasswordClick } from 'services/analytics';

import { useDispatch, useNotify, useSelector } from 'hooks';

import { isEmpty } from 'helpers/dataStructuresUtils';

import FormikInput from 'components/FormikInput';

import * as S from './styled';

import { getFields } from './config';

import { ChangePasswordSchema } from './validationSchema';

const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
    oldPassword: '',
};

export const ChangePassword = () => {
    useScreenLoad();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifySuccess, notifyError } = useNotify();
    const { t } = useTranslation();

    const isLoading: boolean = useSelector(createProcessingSelector([CHANGE_PASSWORD]));

    const { handleSubmit, errors, values, dirty, setFieldTouched, setFieldValue, touched } = useFormik({
        validationSchema: ChangePasswordSchema,
        initialValues,
        onSubmit: () => {
            sendAnalyticSaveNewPasswordClick();

            const settingsTabUrl = `${PATHS.ACCOUNT_DEFAULT}/${accountTabs.Settings}`;

            dispatch(
                changePasswordRequest({
                    payload: {
                        old_password: values.oldPassword,
                        new_password: values.newPassword,
                    },
                    onSuccess: () => {
                        sendAnalyticPasswordUpdate();
                        notifySuccess(t('change.password.success'));
                        navigate(settingsTabUrl);
                    },
                    onError: () => {
                        notifyError(t('change.password.error'));
                        navigate(settingsTabUrl);
                    },
                })
            );
        },
    });

    return (
        <S.Container paddingTop={32} paddingX={16} paddingBottom={80}>
            <Text center mb={20} type="h5" text={t('change.password.title')} />
            <form>
                <Box mb={44}>
                    {getFields().map(({ name, label, type, icon, dataLocator }) => (
                        <FormikInput
                            key={name}
                            type={type}
                            field={name}
                            label={label}
                            setFieldTouched={setFieldTouched}
                            initialValues={initialValues}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            mb={32}
                            leftIcon={icon}
                            dataLocator={dataLocator}
                            placeholder={label}
                        />
                    ))}
                </Box>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={!dirty || !isEmpty(errors)}
                    text={t('save')}
                    mb={24}
                    dataLocator="savePasswordButton"
                />
            </form>
            <Link to={PATHS.AUTHORISED_FORGOT_PASSWORD} data-locator="forgotPasswordBtn">
                <Text type="overline" center bold text={t('basics.forgotPassword')} />
            </Link>
        </S.Container>
    );
};
