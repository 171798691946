import { useEffect } from 'react';

// trackers
import { trackScreenLoad } from './trackers/mainTrackers';

export function useScreenLoad(screenCustomId?: string) {
    useEffect(() => {
        trackScreenLoad(screenCustomId);
    }, []);
}
