import { Text } from 'wikr-core-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'constants/routerPaths';

import { useSelector } from 'hooks';

import Logo from 'components/Logo';

import * as S from './styled';
import { LogInLink } from './styled';

const HeaderPublic = () => {
    const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isRoot = pathname === PATHS.LOGIN;

    return (
        <S.HeaderPublicContainer>
            <S.TitleContainer>
                <Logo />
            </S.TitleContainer>

            {!isRoot && !isAuthenticated && (
                <LogInLink to="/">
                    <Text color="text-main" type="small-button" text={t('basics.login')} />
                </LogInLink>
            )}
        </S.HeaderPublicContainer>
    );
};

export default HeaderPublic;
