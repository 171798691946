import { Box, Switcher as WikrSwitcher } from 'wikr-core-components';
import styled from 'styled-components';

export const RadioContainer = styled(Box)`
    display: flex;
    justify-content: start;
    gap: 71px;
`;

// todo: remove after whiteSpace label issue for Swithcer component will be fixed:
// https://gitlab.asqq.io/vendor-react/components/-/issues/26
export const Switcher = styled(WikrSwitcher)`
    margin: 0;

    > label {
        white-space: nowrap;
    }
`;

export const ShortInputsWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 16px;

    > * {
        max-width: calc(50% - 8px);
    }
`;
