import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import { useSelector } from 'hooks/store';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getFormattedDateByCountry } from 'helpers/date';

import { Alarm } from 'components/Icon';

import * as S from './styled';

import { UserStore } from 'types/store/userStore';
import { IUpcomingProductProps } from '../types';

import SubscriptionData from './SubscriptionData';

const UpcomingProduct = ({ upcomingProduct }: IUpcomingProductProps) => {
    const { t } = useTranslation();

    const currentUser = useSelector(selectCurrentUser) as UserStore;

    const autoRenewalDate = getFormattedDateByCountry(upcomingProduct.started_from, currentUser?.country);

    const subscriptionData = {
        subscriptionPeriod: upcomingProduct.subscription_period,
        subscriptionType: 'subscription',
        price: `${getPriceFromCents(upcomingProduct.amount)} ${upcomingProduct.currency}`,
        expirationDate: replaceUnderscoreToDash(autoRenewalDate),
    };

    return (
        <>
            <S.InactiveStatus>
                <Alarm />
                <Text type="small-text" medium text={t('subscription.status.upcoming')} />
            </S.InactiveStatus>
            <SubscriptionData data={subscriptionData} />
        </>
    );
};

export default UpcomingProduct;
