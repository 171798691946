import { useCallback } from 'react';

import { fetchDiscountSubscriptions } from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { useCaliforniaCancelSubscription, useDispatch } from 'hooks';

const useCaliforniaCancelOfferFlow = () => {
    const dispatch = useDispatch();
    const { cancelSubscriptionHandler, cancellationCandidate: candidateFromStore } = useCaliforniaCancelSubscription();

    const onCaliforniaCancelSubscriptionFlow = useCallback(
        (candidateFromEvent?: CancelCandidate) => {
            const cancellationCandidate = candidateFromEvent || candidateFromStore;

            if (!cancellationCandidate) {
                return;
            }

            const hasReturnOffer = Boolean(cancellationCandidate.discounts.discount);

            const isDiscountAvailable = Boolean(cancellationCandidate.discounts.isDiscountOfferAvailable);

            const isVipSupport = cancellationCandidate.productCode === PRODUCT_CODES.APP_VIP_SUPPORT;

            !hasReturnOffer &&
                dispatch(
                    fetchDiscountSubscriptions({
                        discount_type: DISCOUNT_TYPES.extra,
                        external_id: cancellationCandidate.externalId,
                    })
                );

            const skipReturnOffer = hasReturnOffer || isVipSupport || !isDiscountAvailable;

            cancelSubscriptionHandler(cancellationCandidate, skipReturnOffer);
        },
        [candidateFromStore]
    );

    return { onCaliforniaCancelSubscriptionFlow };
};

export default useCaliforniaCancelOfferFlow;
