import { useTranslation } from 'react-i18next';

import {
    GUIDES_OFFER_MODAL,
    sendAnalyticCancelModalCancelClick,
    sendAnalyticCancelModalOfferClick,
} from 'services/analytics/trackers/cancelOffers';

import { useCancelGuidesOffer, useCancelSubscription } from 'hooks';

import GuidesOfferModal from './components/GuidesOfferModal';

import { getConfig } from './config';

const GuidesOfferContainer = () => {
    const { t } = useTranslation();

    const { onSetOfferGuides } = useCancelGuidesOffer();
    const { onCancelSubscription } = useCancelSubscription();

    const modalData = getConfig(t);

    const cancelSubscriptionHandler = () => {
        sendAnalyticCancelModalCancelClick(GUIDES_OFFER_MODAL);

        onCancelSubscription();
    };
    const setOfferGuidesHandler = () => {
        sendAnalyticCancelModalOfferClick(GUIDES_OFFER_MODAL);

        onSetOfferGuides();
    };

    return (
        <GuidesOfferModal
            modalData={modalData}
            onKeepSubscription={setOfferGuidesHandler}
            onCancelSubscription={cancelSubscriptionHandler}
        />
    );
};

export default GuidesOfferContainer;
