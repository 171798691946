import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const DownloadBlock = styled.div`
    position: relative;
    border-radius: 24px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    background-color: ${color('surface-main')};

    ${mediaQueries.laptop} {
        padding: 48px 0;
    }

    ${mediaQueries.theSmallestDesktop} {
        align-items: start;
    }
`;

export const TitleStepsWrap = styled(Box)`
    padding: 0 20px;
    border-radius: 24px;
    margin-bottom: 34px;

    ${mediaQueries.laptop} {
        padding: 0 48px;
    }

    ${mediaQueries.theSmallestDesktop} {
        margin-bottom: 72px;
        max-width: 517px;
        padding: 0 0 0 48px;
    }
`;

export const InstructionsTitle = styled(Text)`
    font-size: 28px;
    line-height: 36px;

    ${mediaQueries.laptop} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const InstructionsWrap = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    max-width: 495px;

    ${mediaQueries.laptop} {
        padding-left: 24px;
    }

    ${mediaQueries.theSmallestDesktop} {
        max-width: 100%;
    }
`;

export const OneFeature = styled(Box)`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const StepCircle = styled.div`
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${color('primary-default')};
    border-radius: 50%;
`;

export const StepText = styled(Text)`
    font-size: 18px;
    line-height: 28px;
`;

export const ImgWithTextWrap = styled.div`
    max-width: 400px;
    margin-bottom: 40px;

    ${mediaQueries.theSmallestDesktop} {
        margin-bottom: 0;
        position: absolute;
        top: 48px;
        right: 48px;
    }
`;

export const ImgWrap = styled.div`
    position: relative;
    margin-bottom: 22px;
`;

export const ArrowWrap = styled.div`
    position: absolute;
    top: 117px;
    left: -80px;
`;

export const ScanQrCodeWrapper = styled(Box)`
    position: absolute;
    top: 142px;
    left: 125px;
    width: 147px;
    height: 147px;

    > * {
        width: fit-content;
        height: fit-content;
    }
`;

export const GreenTipBlockWrap = styled(Box)`
    width: 100%;
    max-width: calc(100% - 40px);
    display: flex;
    justify-content: center;

    @media (min-width: 490px) {
        max-width: 100%;
    }

    ${mediaQueries.theSmallestDesktop} {
        justify-content: start;
        padding-left: 48px;
    }
`;

export const ButtonFixedWrap = styled.div`
    padding: 44px 16px 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: linear-gradient(
        180deg,
        rgba(242, 239, 238, 0) 0%,
        rgba(242, 239, 238, 0.11) 6.25%,
        rgba(242, 239, 238, 0.21) 11.98%,
        #f2efee 38.54%
    );
    width: 100%;
    margin: 0 auto;

    ${mediaQueries.laptop} {
        display: none;
    }
`;
