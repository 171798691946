import { initReactI18next } from 'react-i18next';
import { useEffect, useState } from 'react';
import ICU from 'i18next-icu';
import HttpApi from 'i18next-http-backend';
import { default as i18n } from 'i18next';

import { DEFAULT_LANGUAGE } from '../constants/localization';

import { getLocalizationFromAvailable, getCurrentLocalization } from '../helpers/localization';

import { getLanguage, setLanguage } from '../api/utils/languageManagment';

const languageFromStorage = getLanguage();
const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

const language = navigatorLanguage || languageFromURL || languageFromStorage || DEFAULT_LANGUAGE;

setLanguage(language);

const useI18next = () => {
    const [isTranslationsLoaded, setLoadingState] = useState(false);

    useEffect(() => {
        i18n.use(HttpApi)
            .use(ICU)
            .use(initReactI18next)
            .init(
                {
                    lng: navigatorLanguage || languageFromURL || languageFromStorage,
                    fallbackLng: [DEFAULT_LANGUAGE],
                    ns: ['translation'],
                    preload: [DEFAULT_LANGUAGE],
                    debug: false,
                    react: {
                        useSuspense: false,
                    },
                    nsSeparator: false,
                    keySeparator: false,
                },
                () => {
                    setLoadingState(true);
                }
            );
    }, []);

    return {
        isTranslationsLoaded,
    };
};

export default useI18next;
