import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonTypes';

export interface UserStore {
    country: string | null;
    date_of_birth: string | null;
    email: string | null;
    fitness_level: string | null;
    gender: string | null;
    goal: string | null;
    height: number | null;
    is_paid: boolean;
    is_password: boolean;
    is_california: boolean;
    language: string | null;
    last_name: string | null;
    localization: string | null;
    name: string | null;
    product_list: null | {
        app_full_access: boolean;
        weight_loss_gd: boolean;
        fat_burning_gd: boolean;
        vip_support: boolean;
        workouts: boolean;
        meal_plan: boolean;
        meal: boolean;
        fatburn: boolean;
    };
    target_bodytype: string | null;
    target_weight: number | null;
    target_zone: string[] | null;
    units: string | null;
    user_id: number | null;
    weight: number | null;
    token: string | null;
    guides_offer: boolean;
}

const initialState: UserStore = {
    country: null,
    date_of_birth: null,
    email: null,
    fitness_level: null,
    gender: null,
    goal: null,
    height: null,
    is_paid: false,
    is_password: false,
    is_california: false,
    language: null,
    last_name: null,
    localization: null,
    name: null,
    product_list: null,
    target_bodytype: null,
    target_weight: null,
    target_zone: null,
    units: null,
    user_id: null,
    weight: null,
    token: null,
    guides_offer: false,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType): UserStore => {
    switch (action.type) {
        case actionTypes.RESET_USER_DATA:
            return { ...initialState };

        case actionTypes.UPDATE_USER_SUCCESS:

        case actionTypes.GET_CURRENT_USER_SUCCESS:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

export default userReducer;
