import { ActionEventAction } from 'wikr-core-analytics';

import config from 'appConfig';

import { getFaqLinks, getPolicyLink } from 'helpers/getFAQLink';
import { getDeviceOs } from 'helpers/getDeviceOs';

const deviceOs = getDeviceOs();

export const SECONDARY_SIDEBAR_LINKS = [
    {
        to: getFaqLinks(deviceOs),
        title: 'sidebar.faq',
        dataLocator: 'burgerFAQ',
        target: '_blank',
        rel: 'noopener noreferrer',
        withSvg: true,
        analyticActionEvent: ActionEventAction.faq,
    },
    {
        to: getPolicyLink(config.links.termsOfUse),
        title: 'sidebar.terms',
        dataLocator: 'burgerTermsOfUse',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.termsOfUse,
    },
    {
        to: getPolicyLink(config.links.privacyPolicy),
        title: 'sidebar.privacy',
        dataLocator: 'burgerPrivacyPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.privacyPolicy,
    },
    {
        to: getPolicyLink(config.links.refundPolicy),
        title: 'sidebar.refund',
        dataLocator: 'burgerRefundPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        analyticActionEvent: ActionEventAction.refundPolicy,
    },
];
