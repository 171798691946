import { useState } from 'react';

import NoConnectionModal from './components';

const NoConnectionModalContainer = () => {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return <NoConnectionModal isLoading={isLoading} onClick={onClick} />;
};

export default NoConnectionModalContainer;
