import { PASSWORD_MATCH_REGEXP } from 'constants/RegExp';

import * as Yup from 'yup';

export const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(PASSWORD_MATCH_REGEXP, 'validation.password.requirements')
        .required('validation.password.required'),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'validation.password.match')
        .required('validation.password.required'),
    oldPassword: Yup.string()
        .matches(PASSWORD_MATCH_REGEXP, 'validation.password.requirements')
        .required('validation.password.required'),
});
