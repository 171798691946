import { ActionEventAction, ElementEventAction, EventTrigger, Main } from 'wikr-core-analytics';

import { customTrackDecorator, trackClick, trackClickCustom, trackScreenLoad } from './mainTrackers';

export const PAUSE_OFFER_MODAL = 'cancel_offer_pause';
export const PAUSE_OFFER_FINAL_MODAL = 'cancel_offer_pause_f';
export const FREE_MONTH_OFFER_MODAL = 'cancel_offer_1m_free';
export const FREE_MONTH_OFFER_FINAL_MODAL = 'cancel_offer_1m_free_f';
export const REMINDER_OFFER_MODAL = 'cancel_offer_reminder';
export const REMINDER_OFFER_FINAL_MODAL = 'cancel_offer_reminder_f';
export const GUIDES_OFFER_MODAL = 'cancel_offer_guides';
export const GUIDES_OFFER_FINAL_MODAL = 'cancel_offer_guides_f';

export const sendAnalyticInsMainSeeAllClick = (categoryId: number) => {
    trackClickCustom({
        event_action: 'button__see_all',
        event_label: String(categoryId),
    });
};

export const sendAnalyticSpecialOfferScreenLoad = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: PAUSE_OFFER_MODAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticCancelModalScreenLoad = (modalId: string) => {
    trackScreenLoad(modalId);
};

export const sendAnalyticCancelModalOfferClick = (modalId: string) => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: modalId,
    });
};

export const sendAnalyticCancelModalCancelClick = (modalId: string) => {
    trackClickCustom(
        {
            event_action: 'button_continue_cancel',
        },
        modalId
    );
};

export const sendAnalyticGuidesOfferFinalDownloadClick = () => {
    trackClickCustom(
        {
            event_action: 'button__download_guides',
        },
        GUIDES_OFFER_FINAL_MODAL
    );
};

export const sendAnalyticPauseResumeClick = () => {
    trackClickCustom({
        event_action: 'button__resume_subscription',
    });
};

export const sendAnalyticPauseResumeSuccess = () => {
    customTrackDecorator('subscription_resume_success');
};

export const sendAnalyticPauseResumeError = (error: string) => {
    customTrackDecorator('subscription_resume_error', '', error);
};
