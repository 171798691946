import { selectCurrentUser } from 'store/user/selectors';
import { setCancellationCandidate, setRestoreCandidate } from 'store/subscriptions/actions';

import { SUBSCRIPTION_HEADINGS, SUBSCRIPTION_TITLE } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';
import { DATE_FORMAT_COMMON } from 'constants/dateFormats';
import {
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_CHARGEBACK,
    SUBSCRIPTION_PAUSED,
    SUBSCRIPTION_REDEMPTION,
} from './constants';

import { sendAnalyticCancelSubscriptionClick, sendAnalyticsRestoreSubscriptionClick } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks/store';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getFormattedDate, getFormattedDateByCountry, getFormattedDateWithPauseByCountry } from 'helpers/date';
import { getAnalyticCancelSubscriptionClickLabel } from './helpers';

import VipSupportIcon from 'assets/img/icons/VipSupportBiggerLogo.svg';
import LogoIcon from 'assets/img/icons/Logo.svg';

import { UserStore } from 'types/store/userStore';
import { ISubscriptionCard } from './types';

const TRIAL_NAME = 'trial';
const SUBSCRIPTION_NAME = 'subscription';

const useSubscriptionCard = ({ onCancelSubscription, showRestoreCandidate, subscription }: ISubscriptionCard) => {
    const {
        product: { amount, currency, trial_amount: trialAmount },
        subscription_period: subscriptionPeriod,
        subscription_trial_period: subscriptionTrialPeriod,
        state,
        discounts,
        is_trial: isTrial,
        expired_date: expiredDate,
        internal_cancel_at: cancelledAt,
        product_code: productCode,
        external_id: externalId,
        upcoming_product: upcomingProduct,
        reminder,
    } = subscription;

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser) as UserStore;

    const isCancelledSubscription = state === SUBSCRIPTION_CANCELLED || Boolean(cancelledAt);
    const isSubscriptionActive = state === SUBSCRIPTION_ACTIVE;
    const isSubscriptionRedemption = state === SUBSCRIPTION_REDEMPTION;
    const isSubscriptionPaused = Boolean(state === SUBSCRIPTION_PAUSED && !discounts.free_month);
    const isFreeMonthActive = Boolean(state === SUBSCRIPTION_PAUSED && discounts.free_month);
    const isExpiredSubscription =
        (isCancelledSubscription && !isSubscriptionActive) || state === SUBSCRIPTION_CHARGEBACK;
    const isExpiresSubscription = isCancelledSubscription && isSubscriptionActive;

    const expirationDate = getFormattedDate(expiredDate, DATE_FORMAT_COMMON);
    const cancelledAtDate = getFormattedDate(cancelledAt, DATE_FORMAT_COMMON);

    const expirationDateWithPauseUI = getFormattedDateWithPauseByCountry(expiredDate, currentUser?.country);

    const expirationDateUI = getFormattedDateByCountry(expiredDate, currentUser?.country);
    const cancelledAtDateUI = getFormattedDateByCountry(cancelledAt, currentUser?.country);

    const tabIcon = productCode === PRODUCT_CODES.APP_VIP_SUPPORT ? VipSupportIcon : LogoIcon;
    const heading = SUBSCRIPTION_HEADINGS[productCode] || SUBSCRIPTION_TITLE.OTHER;
    const price = getPriceFromCents(isTrial ? trialAmount : amount);

    const isMainSubscription = productCode === PRODUCT_CODES.APP_FULL_ACCESS;

    const trackCardExpand = (isTabExpanded: boolean) => {
        console.info('trackCardExpand', isTabExpanded);
    };

    const handleCancelSubscription = () => {
        const cancellationCandidate = {
            expirationDate,
            expirationDateUI,
            externalId,
            productCode,
            state,
            discounts,
            reminder,
        };

        const eventLabel = getAnalyticCancelSubscriptionClickLabel({
            ...discounts,
            reminder,
            guides_offer: currentUser.guides_offer,
        });

        sendAnalyticCancelSubscriptionClick(eventLabel);

        dispatch(setCancellationCandidate(cancellationCandidate));

        onCancelSubscription(cancellationCandidate);
    };

    const handleRestoreSubscription = () => {
        const restoreCandidate = {
            subscriptionPeriod,
            price,
            externalId,
            productCode,
            currency,
        };

        sendAnalyticsRestoreSubscriptionClick(isMainSubscription);

        dispatch(setRestoreCandidate(restoreCandidate));

        showRestoreCandidate(restoreCandidate);
    };

    return {
        price: `${price} ${currency}`,
        state: isFreeMonthActive ? SUBSCRIPTION_ACTIVE : state,
        tabIcon,
        heading,
        upcomingProduct,
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        isSubscriptionPaused,
        isFreeMonthActive,
        cancelledAtDate,
        trackCardExpand,
        handleCancelSubscription,
        handleRestoreSubscription,
        subscriptionType: isTrial ? TRIAL_NAME : SUBSCRIPTION_NAME,
        expirationDate: replaceUnderscoreToDash(expirationDate ? expirationDate : cancelledAtDate),
        expirationDateUI: replaceUnderscoreToDash(expirationDateUI ? expirationDateUI : cancelledAtDateUI),
        expirationDateWithPause: replaceUnderscoreToDash(expirationDateWithPauseUI),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
        isMainSubscription,
    };
};

export default useSubscriptionCard;
