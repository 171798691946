import { GetWorkoutsParams } from 'types/workouts/workoutsApi';

export const getWorkoutsQueryString = (params: GetWorkoutsParams) => {
    let queryString = '';

    Object.entries(params).map(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((itemValue) => {
                queryString += `&${key}=${itemValue}`;
            });
        } else {
            queryString += `&${key}=${value}`;
        }
    });

    return queryString;
};
