import { Image } from 'wikr-core-components';
import React from 'react';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import * as S from 'containers/AuthFlow/styled';

import ForgotPasswordForm from 'components/ForgotPasswordForm';

import ResetPassword from 'assets/images/reset-password.webp';

export const ForgotPassword = () => {
    useScreenLoad();

    return (
        <S.AuthContainer>
            <S.AuthImage>
                <Image src={ResetPassword} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <S.AuthForm>
                <ForgotPasswordForm />
            </S.AuthForm>
        </S.AuthContainer>
    );
};
