import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import { INoConnectionModalProps } from './types';

const NoConnectionModal = ({ isLoading, onClick }: INoConnectionModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen style={{ content: { padding: '24px 24px 0' } }}>
            <Text type="h1" center text="🌐" mb={20} />
            <Text type="h5" text={t('networkErrorModal.title')} bold center mb={12} />
            <Text color="text-secondary" text={t('networkErrorModal.subtitle')} center mb={24} />
            <OutlinedButton isLoading={isLoading} text={t('basics.tryAgain')} onClick={onClick} mb={20} />
        </Modal>
    );
};

export default NoConnectionModal;
