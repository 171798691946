import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { ReactComponent as Check } from './img/Check.svg';

function GreenTipContainer({ text }: { text: string }) {
    const { t } = useTranslation();

    return (
        <S.GreenTipBlock borderRadius={24} paddingBottom={24} paddingX={24} paddingTop={24}>
            <S.CheckSVGWrap>
                <Check />
            </S.CheckSVGWrap>
            <Text type="large-text" text={t(text)} />
        </S.GreenTipBlock>
    );
}

export default GreenTipContainer;
