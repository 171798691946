import ApiWorkout from 'services/ApiWorkout';

import { getWorkoutsQueryString } from 'helpers/workouts';

import { IWorkoutsData, WorkoutDetails, ExerciseDetails } from 'types/workouts/workoutsApiInterface';
import { GetWorkoutsParams } from 'types/workouts/workoutsApi';

class WorkoutApi {
    getWorkouts = async <Response = IWorkoutsData>(params: GetWorkoutsParams): Promise<Partial<Response>> => {
        const queryParams = getWorkoutsQueryString(params);

        return await ApiWorkout.get<Response>({ url: `workouts/?${queryParams}` });
    };

    getWorkoutDetails = async <Response = WorkoutDetails>(workoutId: string): Promise<Partial<Response>> => {
        return await ApiWorkout.get<Response>({ url: `workouts/${workoutId}` });
    };

    getExerciseDetails = async <Response = ExerciseDetails>(exerciseId: number): Promise<Partial<Response>> => {
        return await ApiWorkout.get<Response>({ url: `exercises/${exerciseId}` });
    };
}

export default WorkoutApi;
