import { useTranslation } from 'react-i18next';

import { selectCurrentUserId } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import { subscriptionSentryLogger } from 'store/subscriptions/helpers';
import { cancelSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DEFAULT_CANCEL_SUBSCRIPTION_ERROR } from 'constants/subscriptions';

import { sendAnalyticSubscriptionCancelError, sendAnalyticSubscriptionCancelSuccess } from 'services/analytics';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { getUpdatingSubscriptionLoadingData } from 'hooks/subscriptions/helpers';
import { useDispatch, useModals, useSelector } from 'hooks';

import { getCancelErrorData, getCancelSuccessData } from '../useCancelSubscription/helpers/cancelSubscriptionHelpers';

import { ClientError } from 'welltech-web-api-client';

const useCaliforniaCancelSubscription = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();
    const userId = useSelector(selectCurrentUserId);

    const { cancellationCandidate: candidateFromStore } = useSelector(selectSubscriptions);
    const cancelSubscriptionHandler = (candidate: CancelCandidate, skipReturnOffer: boolean) => {
        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        dispatch(
            cancelSubscription({
                externalId: candidate.externalId,
                onError: (err?: unknown) => {
                    // @ts-ignore
                    sendAnalyticSubscriptionCancelError(err?.message || DEFAULT_CANCEL_SUBSCRIPTION_ERROR);

                    subscriptionSentryLogger(err as ClientError, userId);

                    openModal(ModalName.errorFeedbackModal, {
                        modalData: getCancelErrorData({
                            onCancelSubscription: () => cancelSubscriptionHandler(candidate, skipReturnOffer),
                            t,
                        }),
                    });
                },
                onSuccess: () => {
                    sendAnalyticSubscriptionCancelSuccess();

                    if (skipReturnOffer) {
                        openModal(ModalName.successFeedbackModal, {
                            modalData: getCancelSuccessData({ closeModal, t }),
                        });
                    } else {
                        openModal(ModalName.californiaReasonModal);
                    }
                },
            })
        );
    };

    return {
        cancelSubscriptionHandler,
        cancellationCandidate: candidateFromStore,
    };
};

export default useCaliforniaCancelSubscription;
