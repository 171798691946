import { getUIStoreData } from 'store/uiEffects/accessors';
import { getAuthStoreData } from 'store/auth/accessors';

export const isPrivate = () => {
    const { isAuthenticated, isComplete } = getAuthStoreData();

    return isAuthenticated && isComplete;
};

export const isAppLibraryWebview = () => Boolean(getUIStoreData()?.isAppWebView);

export const isAppWebview = () => Boolean(getUIStoreData()?.isAppWebView || getUIStoreData()?.isWorkoutsAppWebView);
