import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FEATURES } from './constants';

import InstallAppButton from 'components/InstallAppButton';

import * as S from './styled';

import featuresPhone from './img/featuresPhone.webp';

import GreenTipContainer from '../GreenTipContainer';

interface IFeaturesBlockProps {
    onFeatureClick: () => void;
    isAndroid: RegExpMatchArray | null;
    isIos: RegExpMatchArray | null;
}

function FeaturesBlock({ onFeatureClick, isAndroid, isIos }: IFeaturesBlockProps) {
    const { t } = useTranslation();

    return (
        <S.FeaturesBlock mb={32} backgroundColor="surface-main">
            <S.TitleFeaturesWrap>
                <S.FeaturesTitle mb={24} medium text={t('main.featureBlock.title')} />
                <S.FeaturesWrap>
                    {FEATURES.map((feature, index) => (
                        <S.OneFeature onClick={onFeatureClick} key={index}>
                            {feature.img()}
                            <S.FeatureName text={t(feature.title)} />
                        </S.OneFeature>
                    ))}
                </S.FeaturesWrap>
            </S.TitleFeaturesWrap>
            <S.MobileStoreWrap>
                {isIos && <InstallAppButton type="apple" dataLocator="appleStoreLink" />}
                {isAndroid && <InstallAppButton type="google" dataLocator="googleStoreLink" />}
                {!isIos && !isAndroid && (
                    <>
                        <InstallAppButton smallBtn type="apple" dataLocator="appleStoreLink" />
                        <InstallAppButton smallBtn type="google" dataLocator="googleStoreLink" />
                    </>
                )}
            </S.MobileStoreWrap>
            <S.ImgWrap>
                <Image isForceVisible src={featuresPhone} alt="featuresPhone" />
            </S.ImgWrap>
            <S.GreenTipBlockWrap>
                <GreenTipContainer text="main.featureBlock.greenBlock" />
            </S.GreenTipBlockWrap>
        </S.FeaturesBlock>
    );
}

export default FeaturesBlock;
