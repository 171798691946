import * as actionTypes from './actionTypes';

import { SagaActionsProps } from 'types/commonInterfaces';

export const getDeepLinkRequest = (payload: SagaActionsProps) => {
    return {
        type: actionTypes.GET_DEEP_LINK_REQUEST,
        payload,
    } as const;
};

export const getDeepLinkSuccess = (payload: string) => {
    return {
        type: actionTypes.GET_DEEP_LINK_SUCCESS,
        payload,
    } as const;
};

export const getDeepLinkError = (payload: Error) => {
    return {
        type: actionTypes.GET_DEEP_LINK_ERROR,
        payload,
    } as const;
};
