import { isAuthenticatedSelector } from 'store/auth/selectors';

import { PATHS } from 'constants/routerPaths';

import { useSelector } from 'hooks';

import * as S from './styled';

import { ILogoProps } from './types';

const Logo = ({ withoutText, height }: ILogoProps) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const logoLink = isAuthenticated ? PATHS.MAIN : PATHS.LOGIN;

    return (
        <S.LogoContainer to={logoLink} height={height}>
            <S.LogoImage />
            {!withoutText && <S.LogoText />}
        </S.LogoContainer>
    );
};

export default Logo;
