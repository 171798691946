import { ReactComponent as Timer } from 'assets/img/icons/Timer.svg';
import { ReactComponent as Statistics } from 'assets/img/icons/Statistics.svg';
import { ReactComponent as Recipes } from 'assets/img/icons/Recipes.svg';
import { ReactComponent as Insights } from 'assets/img/icons/Insights.svg';
import { ReactComponent as Dashboard } from 'assets/img/icons/Dashboard.svg';

export const FEATURES = [
    {
        img: () => <Dashboard />,
        title: 'main.featureBlock.feature1',
    },
    {
        img: () => <Timer />,
        title: 'main.featureBlock.feature2',
    },
    {
        img: () => <Recipes />,
        title: 'main.featureBlock.feature3',
    },
    {
        img: () => <Insights />,
        title: 'main.featureBlock.feature4',
    },
    {
        img: () => <Statistics />,
        title: 'main.featureBlock.feature5',
    },
];
