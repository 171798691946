import { RouterProvider } from 'react-router-dom';

import { useNetworkStatus } from 'hooks/common';

import NotificationsContainer from 'containers/Notifications';

import ThemeProvider from './theme/provider';
import router from './router';

function App() {
    useNetworkStatus();

    return (
        <ThemeProvider>
            <NotificationsContainer />
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}

export default App;
