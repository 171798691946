import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUserId } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import { subscriptionSentryLogger } from 'store/subscriptions/helpers';
import {
    cancelSubscription,
    fetchDiscountSubscriptions,
    fetchSubscriptions,
    setCancellationCandidate,
    setDiscountSubscription,
    updateSubscription,
} from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DEFAULT_CANCEL_SUBSCRIPTION_ERROR, DISCOUNT_TYPES } from 'constants/subscriptions';

import {
    sendAnalyticSpecialOfferError,
    sendAnalyticSpecialOfferGetDiscountClick,
    sendAnalyticSpecialOfferSuccess,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { useDispatch, useModals, useSelector } from 'hooks';

import { getUpdatingErrorData, getUpdatingSuccessData } from './helpers/updateSubscriptionHelpers';
import { getCancelErrorData, getCancelSuccessData } from './helpers/cancelSubscriptionHelpers';
import { getUpdatingSubscriptionLoadingData } from '../../helpers';

import { ClientError } from 'welltech-web-api-client';

const useCancelSubscription = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { discountSubscription, cancellationCandidate: candidateFromStore } = useSelector(selectSubscriptions);
    const userId = useSelector(selectCurrentUserId);

    const { t } = useTranslation();

    const discardCancellation = () => {
        closeModal();

        dispatch(setCancellationCandidate(null));

        if (discountSubscription) {
            dispatch(setDiscountSubscription(null));
        }
    };

    const cancelSubscriptionHandler = (candidate: CancelCandidate) => {
        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        dispatch(
            cancelSubscription({
                externalId: candidate.externalId,
                onError: (err?: unknown) => {
                    // @ts-ignore
                    sendAnalyticSubscriptionCancelError(err?.message || DEFAULT_CANCEL_SUBSCRIPTION_ERROR);

                    subscriptionSentryLogger(err as ClientError, userId);

                    openModal(ModalName.errorFeedbackModal, {
                        modalData: getCancelErrorData({
                            onCancelSubscription: () => cancelSubscriptionHandler(candidate),
                            t,
                        }),
                    });
                },

                onSuccess: () => {
                    sendAnalyticSubscriptionCancelSuccess();

                    if (!candidate.discounts.discount && candidate.discounts.isDiscountOfferAvailable) {
                        openModal(ModalName.returnOfferModal);
                    } else {
                        discardCancellation();
                        openModal(ModalName.successFeedbackModal, {
                            modalData: getCancelSuccessData({ closeModal, t }),
                        });
                    }
                },
                onCancel: () => {
                    discardCancellation();

                    openModal(ModalName.successFeedbackModal, {
                        modalData: getCancelSuccessData({ closeModal, t }),
                    });
                },
            })
        );
    };

    const onCancelSubscriptionOneClick = (candidate: CancelCandidate) => {
        candidate.externalId && cancelSubscriptionHandler(candidate);
    };

    const onCancelSubscription = useCallback(() => {
        if (candidateFromStore) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: candidateFromStore.externalId,
                })
            );
            cancelSubscriptionHandler(candidateFromStore);
        }
    }, [candidateFromStore]);

    const onUpdateSubscription = useCallback(() => {
        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        if (candidateFromStore && discountSubscription) {
            sendAnalyticSpecialOfferGetDiscountClick();
            dispatch(
                updateSubscription({
                    updatedSubscription: {
                        external_id: candidateFromStore.externalId,
                        product: discountSubscription,
                    },
                    onSuccess: () => {
                        discardCancellation();
                        sendAnalyticSpecialOfferSuccess(discountSubscription.id);
                        dispatch(fetchSubscriptions());

                        openModal(ModalName.successFeedbackModal, {
                            modalData: getUpdatingSuccessData({ closeModal, discountSubscription, t }),
                        });
                    },
                    onError: () => {
                        sendAnalyticSpecialOfferError();

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getUpdatingErrorData({ onUpdateSubscription, t }),
                        });
                    },
                })
            );
        }
    }, [candidateFromStore, discountSubscription]);

    return {
        discardCancellation,
        onCancelSubscription,
        onCancelSubscriptionOneClick,
        onUpdateSubscription,
        cancellationCandidate: candidateFromStore,
    };
};

export default useCancelSubscription;
