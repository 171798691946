import { useEffect } from 'react';

import { getLanguage } from 'api/utils/languageManagment';

import { selectCurrentUser } from 'store/user/selectors';
import { selectHideFrontChat } from 'store/uiEffects/selectors';

import { initFrontChat, shutdownFrontChat } from 'services/FrontChat';

import { useSelector } from 'hooks';

function useFrontChat() {
    const language = getLanguage();
    const { email, name } = useSelector(selectCurrentUser);
    const { isAppWebView, isWorkoutsAppWebView } = useSelector(({ ui }) => ui);

    const isHideFrontChat = useSelector(selectHideFrontChat);

    useEffect(() => {
        if (!isHideFrontChat && !(isAppWebView || isWorkoutsAppWebView)) {
            initFrontChat({ name, email, language });
        }

        return () => shutdownFrontChat();
    }, []);
}

export default useFrontChat;
