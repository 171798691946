import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { fetchDiscountSubscriptions } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { useCancelSubscription, useDispatch, useModals, useSelector } from 'hooks';

import { getVipSupportCancelModalData, getSecondSubscriptionCancelModalData } from '../helpers';

const useCancelOfferFlow = () => {
    const dispatch = useDispatch();
    const { openModal } = useModals();
    const { guides_offer } = useSelector(selectCurrentUser);
    const { onCancelSubscriptionOneClick, cancellationCandidate: candidateFromStore } = useCancelSubscription();
    const { t } = useTranslation();

    const handleVipSupportCancel = () => {
        openModal(ModalName.secondSubscriptionCancelModal, {
            modalData: getVipSupportCancelModalData(t),
        });
    };

    const handleSecondSubscriptionCancel = (productCode: string, expirationDateUI: string) => {
        openModal(ModalName.secondSubscriptionCancelModal, {
            modalData: getSecondSubscriptionCancelModalData({
                t,
                productCode,
                expirationDateUI,
            }),
        });
    };

    const handleMainSubscriptionCancelFlow = () => {
        openModal(ModalName.reasonModal);
    };

    const handleDiscountOfferFlow = () => {
        openModal(ModalName.specialOfferModal);
    };

    const onCancelSubscriptionFlow = useCallback(
        (candidateFromEvent?: CancelCandidate) => {
            const cancellationCandidate = candidateFromEvent || candidateFromStore;

            if (!cancellationCandidate) {
                return;
            }

            const hasDiscount = Boolean(cancellationCandidate.discounts.discount);
            const isDiscountAvailable = Boolean(cancellationCandidate.discounts.isDiscountOfferAvailable);

            const isVipSupport = cancellationCandidate.productCode === PRODUCT_CODES.APP_VIP_SUPPORT;

            const isSecondSubscription =
                cancellationCandidate.productCode !== PRODUCT_CODES.APP_VIP_SUPPORT &&
                cancellationCandidate.productCode !== PRODUCT_CODES.APP_FULL_ACCESS;

            if (isVipSupport) {
                handleVipSupportCancel();

                return;
            }

            if (isSecondSubscription) {
                handleSecondSubscriptionCancel(
                    cancellationCandidate.productCode,
                    cancellationCandidate.expirationDateUI
                );

                return;
            }

            if (!isDiscountAvailable) {
                onCancelSubscriptionOneClick(cancellationCandidate);

                return;
            }

            !hasDiscount &&
                dispatch(
                    fetchDiscountSubscriptions({
                        discount_type: DISCOUNT_TYPES.base,
                        external_id: cancellationCandidate.externalId,
                    })
                );

            const hasOffer = Boolean(
                cancellationCandidate.discounts.pause ||
                    cancellationCandidate.discounts.free_month ||
                    cancellationCandidate.reminder ||
                    guides_offer
            );

            if (!hasOffer && !hasDiscount) {
                handleMainSubscriptionCancelFlow();
            } else if (hasOffer && !hasDiscount) {
                handleDiscountOfferFlow();
            } else if (hasDiscount) {
                onCancelSubscriptionOneClick(cancellationCandidate);
            }
        },
        [candidateFromStore]
    );

    return { onCancelSubscriptionFlow };
};

export default useCancelOfferFlow;
