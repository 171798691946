import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { createElement } from 'react';

import { RootLayout } from 'router/layouts';

import { getProtectedRouteList } from './helpers/getRouteList';

import ErrorBoundary from 'components/ErrorBoundary';

import { RouteType } from './types';

import { ROUTES } from './routes';
import ProtectedRoute from './ProtectedRoute';

const createRoutesProtected = (routes: RouteType[]) =>
    routes.map(({ path, titleKey, component: Component, layout, validators, redirectPath }) => (
        <Route
            key={path}
            path={path}
            element={createElement(
                layout,
                { titleKey },
                // @ts-ignore
                <ProtectedRoute validators={validators} redirectPath={redirectPath}>
                    <Component />
                </ProtectedRoute>
            )}
        />
    ));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<ErrorBoundary />}>
            {createRoutesProtected(getProtectedRouteList(ROUTES))}
        </Route>
    )
);

export default router;
