import React, { useEffect } from 'react';

import {
    PAUSE_OFFER_MODAL,
    sendAnalyticCancelModalCancelClick,
    sendAnalyticCancelModalOfferClick,
    sendAnalyticCancelModalScreenLoad,
} from 'services/analytics/trackers/cancelOffers';

import { useCancelSubscription, usePauseSubscription } from 'hooks';

import PauseOfferModal from './components/PauseOfferModal';

const PauseOfferModalContainer = () => {
    const { onCancelSubscription } = useCancelSubscription();
    const { onPauseSubscription } = usePauseSubscription();

    const offerSubscriptionHandler = () => {
        sendAnalyticCancelModalOfferClick(PAUSE_OFFER_MODAL);

        onPauseSubscription();
    };
    const cancelSubscriptionHandler = () => {
        sendAnalyticCancelModalCancelClick(PAUSE_OFFER_MODAL);

        onCancelSubscription();
    };

    useEffect(() => {
        sendAnalyticCancelModalScreenLoad(PAUSE_OFFER_MODAL);
    }, []);

    return (
        <PauseOfferModal
            onCancelSubscription={cancelSubscriptionHandler}
            onPauseSubscription={offerSubscriptionHandler}
        />
    );
};

export default PauseOfferModalContainer;
